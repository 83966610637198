<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-hover table-bordered text-center" id="hub_stock_consumption_history">
            <thead>
            <tr>
              <th>#</th>
              <th>Product</th>
              <th>Qty</th>
              <th>Entry Date</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(hub_consumption, index) in hub_consumptions.data" :key="index">
              <td>{{ hub_consumptions.from + index }}</td>
              <td>{{ hub_consumption.free_purchase_request_item.name }}</td>
              <td>{{ hub_consumption.amount }}</td>
              <td>{{ hub_consumption.entry_date }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="hub_consumptions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="hub_consumptions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      hub_consumptions: {},
      loading: false,
      show: false,
      flag: false,
      btnLoading: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        date_range: '',
      },
    }
  },
  mounted() { },
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    search() {
      this.loading = true
      this.btnLoading = true
      this.flag = true
      apiClient.post('api/hub/free/product-stock/consumption-history', this.search_data)
        .then(response => {
          this.loading = false
          this.btnLoading = false
          this.flag = true
          this.hub_consumptions = response.data.hub_consumptions
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/hub/free/product-stock/consumption-history?page=' + page, this.search_data)
        .then(response => {
          this.hub_consumptions = response.data.hub_consumptions
        })
    },
  },
}
</script>
